import { Flex, HStack, Icon, Text, Tooltip, VStack } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import React from 'react'
import { MdDownload, MdUpload } from 'react-icons/md'

import CasesAvatarGroup from '../components/CasesAvatarGroup'
import CaseRowListBox from './CaseRowListBox'

const dateFormat: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}

type CaseRowProps = {
  caseUid: string
  title: string
  dateTime: Date
  messages: { Inbound: number; Outbound: number; AutoReply: number }
  userUids: string[]
  isSelected: boolean
  onSelectCase: (
    uid: string,
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void
}

const CaseListItem = React.memo<CaseRowProps>(function CasesRow({
  caseUid,
  title,
  dateTime,
  messages,
  isSelected,
  onSelectCase,
  userUids,
}) {
  const handleSelectConversation = React.useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      onSelectCase(caseUid, e)
    },
    [onSelectCase, caseUid],
  )
  return (
    <CaseRowListBox isSelected={isSelected} onClick={handleSelectConversation}>
      <Flex
        alignItems="start"
        justifyContent="space-between"
        direction="column"
        color="gray.600"
        h="100%"
        w="100%"
      >
        <VStack alignItems="start" spacing={0}>
          <Text
            color={isSelected ? 'primary.500' : 'gray.800'}
            fontWeight={isSelected ? 'medium' : 'regular'}
            minH={5}
            noOfLines={2}
          >
            {title}
          </Text>
          <HStack fontSize="sm" spacing={1}>
            <Text>{i18n.date(dateTime, dateFormat)}</Text>
          </HStack>
        </VStack>
        <Flex alignItems="center" justifyContent="space-between" w="100%">
          <HStack>
            <CasesAvatarGroup userUids={userUids} size="xs" />
          </HStack>
          <HStack>
            <Tooltip label={t`Received`} hasArrow placement="top">
              <HStack gap="0" align="center">
                <Icon boxSize="14px" as={MdDownload} />
                <Text color="gray.600" fontSize="xs">
                  {messages.Inbound}
                </Text>
              </HStack>
            </Tooltip>
            <Tooltip label={t`Sent`} hasArrow placement="top">
              <HStack gap="0" align="center">
                <Icon boxSize="14px" as={MdUpload} />
                <Text color="gray.600" fontSize="xs">
                  {messages.Outbound + messages.AutoReply}
                </Text>
              </HStack>
            </Tooltip>
          </HStack>
        </Flex>
      </Flex>
    </CaseRowListBox>
  )
})

export default CaseListItem
