import {
  Flex,
  HStack,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  VStack,
} from '@chakra-ui/react'
import React from 'react'

import CaseRowListBox from './CaseRowListBox'

const CaseRowSkeleton = React.memo(function ConversationRowSkeleton() {
  return (
    <CaseRowListBox>
      <Flex
        alignItems="start"
        justifyContent="space-between"
        direction="column"
        color="gray.600"
        h="100%"
        w="100%"
      >
        <VStack alignItems="start" spacing={1} w="100%">
          <Skeleton borderRadius="md" h="4" w="80%">
            <SkeletonText mt="2" noOfLines={1} spacing="1" />
          </Skeleton>
          <Skeleton borderRadius="md" w="70%" h="3" />
        </VStack>
        <Flex alignItems="center" justifyContent="space-between" w="100%">
          <HStack>
            <SkeletonCircle w={6} h={6} />
            <Skeleton w={10} h="2" />
          </HStack>
          <Skeleton w={10} h="2" />
        </Flex>
      </Flex>
    </CaseRowListBox>
  )
})

export default CaseRowSkeleton
