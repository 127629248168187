import { ListItem } from '@capturi/ui-components'
import React from 'react'

const CaseRowListBox: React.FC<{
  isSelected?: boolean
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  children?: React.ReactNode
}> = ({ isSelected = false, onClick, children }) => (
  <ListItem
    background={isSelected ? 'white' : 'gray.50'}
    borderLeftColor={isSelected ? 'primary.500' : 'gray.200'}
    borderLeftWidth={isSelected ? 2 : 0}
    cursor="pointer"
    hasDivider
    h={24}
    px={2}
    onClick={onClick}
  >
    {children}
  </ListItem>
)

export default CaseRowListBox
